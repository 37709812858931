import axios from 'axios';
import Notify from 'simple-notify'
import isUrl from 'is-url';
import 'simple-notify/dist/simple-notify.min.css'

if(window.DealerBoost === undefined) window.DealerBoost = {functions: {}, channels: {}}

let dealerId = window.location.pathname.split('/')[2];
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

function pushNotify(status, text) {
    let timeout = status === 'success' ? 1500 : 2000;
    new Notify({
        status: status,
        text: text,
        effect: 'fade',
        speed: 300,
        showIcon: false,
        showCloseButton: true,
        autoclose: true,
        autotimeout: timeout,
        gap: 20,
        distance: 80,
        type: 3,
        position: 'bottom x-center'
    })
}


DealerBoost.functions.setupAutoSubmit = function(path) {
    let items = document.querySelectorAll('#configure-sales input[type="text"], #configure-sales input[type="url"], #configure-sales textarea, #configure-service input[type="text"], #configure-service input[type="url"], #configure-service textarea')
    for (let i = 0, len = items.length; i < len; i++) {
        items[i].addEventListener("focusout", onFocusOut);
    }

    function onFocusOut() {
        let content = this.value;
        if (this.dataset.type === 'url' && content !== '') {
            if (!isUrl(content)) {
                pushNotify('error', 'Not saved! Must be a URL!')
                return;
            }
        }
        axios.put(path, {
            content: this.value,
            id: this.dataset.id
        }, {
            responseType: 'json',
        }).then(res => {
            pushNotify(res.data.status, res.data.text);
        }).catch(err => {
            pushNotify(err.data.status, err.data.text);
        })
    }
}
